<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :popupParam="popupParam"
          :educationInfo.sync="educationInfo"
          :is="tab.component"
          @changeStatus="changeStatus"
          @closePopup="closePopup"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'education-coursen-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      educationInfo: {
        eduCourseId: '',  // 교육과정 일련번호
        plantCd: '',  // 사업장 코드
        educationCourseName: '',  // 교육과정명
        educationKindCdLarge: null,  // 교육종류1
        educationKindCdSmall: null,  // 교육종류2
        educationTypeCd: null,  // 전사자체/부서자체/사내위탁/사외교육/연수/기타
        legalEducationFlag: 'Y',  // 법정교육 여부
        sumEducationTime: '',  // 교육시간
        educationCycle: null,  // 주기
        educationPurpose: '',  // 학습목적
        mainmainTargetAudience: '',  // 기술
        estimatedEducationExpenses: '',  // 예상 교육비
        relationLaws: '',  // 관련 법규
        useFlag: 'Y',
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
        educationLocation: '', // 교육장소
        educationMethodCd: null, //교육방법
        ramRiskAssessmentPlanId: '', //3단계판단법 일련번호
        vod: null, // 동영상교육소스
        regUserId: '',
        chkUserId: '',

        eduCourseSubjectList: [], // 과정별 교육과목
        deleteEduCourseSubjectList: [], // 제외과목
        eduCourseUserList: [], // 대상자
        deleteEduCourseUserList: [], // 제외 대상자
        eduCourseDeptList: [], // 대상부서
        deleteEduCourseDeptList: [], // 제외 대상부서
        eduCourseSeniorList: [], // 대상 선임
        deleteEduCourseSeniorList: [], // 제외 대상 선임
      },
      tab: 'educationCourseInfo',
      tabItems: [],
      addTabItems: [],
      getUrl: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_CURRICULUM',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.edu.course.get.url;
      this.tabItems = [
        // 교육과정 기본정보
        { name: 'educationCourseInfo', icon: 'info', label: 'LBL0002807', component: () => import(`${'./educationCourseInfo.vue'}`) }
      ]
      this.addTabItems = [
        // 기본 교육대상자
        { name: 'educationCourseUser', icon: 'face', label: 'LBL0002808', component: () => import(`${'./educationCourseUser.vue'}`) },
        // 교육교재
        // { name: 'educationCourseTextBook', icon: 'file_copy', label: 'LBL0002809', component: () => import(`${'./educationCourseTextBook.vue'}`) },
        // 교육과정별 교육계획 및 결과
        { name: 'educationCourseHistory', icon: 'history', label: 'LBL0002810', component: () => import(`${'./educationCourseHistory.vue'}`) },
      ]
      if (this.popupParam.eduCourseId) {this.tabItems = this.tabItems.concat(this.addTabItems);}
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = this.$_.clone(_result.data);
          this.$set(this.attachInfo, 'taskKey', this.popupParam.eduCourseId);
        },);
      }
    },
    changeStatus() {
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>
